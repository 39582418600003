@font-face {
  font-family: "Involve-Regular", sans-serif;
  src: url(./assets/fonts/Involve-Regular.otf);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Involve-Regular", sans-serif;
  font-weight: 300;
}

body,
html {
  min-height: 100vh;
  max-width: 100vw;
  text-align: center;
}
